import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from './axios-cortex';
import Swal from 'sweetalert2';
import Spinner from './components/Spinner/Spinner';

class App extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            isModalOpen: false,
            isLoading: false,
            x: window.matchMedia("(min-width: 1200px)"),
            recaptchaToken: null,
            expired: false,
            nome: '',
            telefone: '',
            cidade: '',
            email: '',
            empresa: '',
            cargo: '',
            mensagem: ''
        }
        this._reCaptchaRef = React.createRef();
        this._reCaptchaRef2 = React.createRef();
    }

    componentDidMount() {
        this.state.x.addListener(this.getExpenseImage);
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    sendForm = (event) => {
        event.preventDefault();

        if (!this.state.expired && this.state.recaptchaToken
            && this.formContatoEl.checkValidity()) {
            this.setState({ isLoading: true });

            return axios.post('site-form', {
                nome: this.state.nome,
                email: this.state.email,
                telefone: this.state.telefone,
                cidade: this.state.cidade,
                empresa: this.state.empresa,
                cargo: this.state.cargo,
                mensagem: this.state.mensagem.replace(/\n/g, ' ')
            }).then(data => {
                this.setState({ isLoading: false, recaptchaToken: null });
                Swal.fire({
                    type: 'success',
                    title: 'Sucesso!',
                    text: 'O formulário foi enviado, logo entraremos em contato!',
                    showConfirmButton: false,
                    timer: 1500
                });
            }).catch(() => {
                Swal.fire({
                    type: 'error',
                    title: 'Erro!',
                    text: 'Não conseguimos enviar seu formulário, tente novamente mais tarde!',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
        }
    }

    enviarFormConteudosExclusivos = (event) => {
        event.preventDefault();

        if (!this.state.expired && this.state.recaptchaToken
            && this.formEl.checkValidity()) {
            this.setState({ isLoading: true });

            return axios.post('mkt-form', {
                nome: this.state.nome,
                email: this.state.email
            }).then(data => {
                this.setState({ isLoading: false, recaptchaToken: null });
                Swal.fire({
                    type: 'success',
                    title: 'Sucesso!',
                    text: 'Envio realizado com sucesso!',
                    showConfirmButton: false,
                    timer: 1500
                });
            }).catch(() => {
                Swal.fire({
                    type: 'error',
                    title: 'Erro!',
                    text: 'Não conseguimos realizar o envio, tente novamente mais tarde!',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
        }
    }

    openModal = () => {
        this.setState({ isModalOpen: true });
    }

    closeModal = () => {
        this.setState({ isModalOpen: false });
    }

    recapcthaCHange = value => {
        this.setState({ recaptchaToken: value });
        if (value === null) this.setState({ expired: true });
    }

    getRecaptcha = (captchaRef) => {
        return (
            <ReCAPTCHA
                style={{ display: "inline-block" }}
                ref={captchaRef}
                sitekey="6LfvIZMUAAAAAF767zj4DIG3rvIEr6zm6IVXXQem"
                onChange={this.recapcthaCHange}
            />
        );
    }

    getBanner() {
        if (this.state.x.matches) {
            return (
                <div className="banner">
                    <div className="bg img-fluid"></div>
                    <div className="row align-items-center justify-content-center cc-vh-85">
                        <div className="col-md-10 cc-banner cc-animate">
                            <div className="cc-title-banner">
                                <h1>TRANSFORMAÇÃO</h1>
                                <h1 className="text-end"><span>DIGITAL</span></h1>
                            </div>
                            <h1>O seu hospital está preparado?</h1>
                            <div>
                                <a className="btn btn-primary cc-animate" href="https://ebook.cortexclass.com">
                                    Acesse o nosso guia de inovação
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="container">
                <div style={{'padding': '10px'}} className="col-sm-12 d-flex flex-column align-items-center justify-content-center cc-animate h-100 text-center">
                    <div className="cc-title-banner">
                        <h2>TRANSFORMAÇÃO</h2>
                        <h2><span>DIGITAL</span></h2>
                    </div>
                    <h3>O seu hospital está preparado?</h3>
                    <div>
                        <a className="btn btn-primary cc-animate" href="https://ebook.cortexclass.com">
                            Acesse o nosso guia de inovação
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-xl navbar-dark cc_navbar bg-dark cc-navbar-light" id="cc-navbar">
                    <div className="container-fluid">
                        <a className="smoothscroll navbar-brand" href="#section-home">
                            <img id="cortex-logo" alt="Cortex Class" style={{
                                maxWidth: '200px'
                            }} src='./images/cortex-logo-white.png' />
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#cc-nav" aria-controls="cc-nav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="oi oi-menu"></span> Menu
                        </button>

                        <div className="collapse navbar-collapse" id="cc-nav">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active"><a href="#section-home" className="nav-link">Início</a></li>
                                <li className="nav-item"><a href="#section-cortex" className="nav-link">A Cortex</a></li>
                                <li className="nav-item"><a href="#section-educacao-corporativa" className="nav-link">Educação Corporativa</a></li>
                                <li className="nav-item"><a href="#section-modulos" className="nav-link">Módulos</a></li>
                                <li className="nav-item"><a href="#section-hacks-meetups" className="nav-link">Hacks e Meetups</a></li>
                                <li className="nav-item"><a href="#section-consultoria" className="nav-link">Consultoria</a></li>
                                <li className="nav-item"><a href="#section-conteudo" className="nav-link">Conteúdo</a></li>
                                <li className="nav-item"><a href="#section-contato" className="nav-link">Contato</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>

                <section className="cc-cover cc-slant" id="section-home">
                    <div className="row cc-vh-85">
                        {this.getBanner()}
                    </div>
                </section>

                <section className="cc-section cc-slant cc-slant-white no-pd-bt" id="section-cortex">
                    <div className="container container-img">
                        <div className="row">
                            <div className="col-md-12 cc-animate">
                                <div className="cc-title text-center">
                                    <h2><span>&#60;</span> A CORTEX <span>&#62;</span></h2>
                                </div>
                                <div className="row d-flex justify-content-center cc-section-margin-top">
                                    <div className="d-flex flex-column col-sm-12 col-md-12 col-lg-6 text-justify cc-animate">
                                        <p className="head-title">
                                            ESTRATÉGIA, GESTÃO E INOVAÇÃO PARA EMPRESAS E PROFISSIONAIS DA SAÚDE.
                                        </p>
                                        <p className="lead">
                                            Somos especialistas em Lean Healthcare e Transformação Digital na saúde, atuamos na área de educação corporativa e no desenvolvimento de projetos de consultoria em hospitais, clínicas e laboratórios.
                                        </p>
                                        <p className="lead">
                                            Acreditamos que, em um mundo em constante mudança, adotar uma CULTURA DE INOVAÇÃO deixou de ser uma vantagem competitiva: é uma questão de sobrevivência.
                                        </p>
                                        <p className="lead">
                                            Na teoria da evolução, não é o mais forte que sobrevive, mas o que melhor lê o ambiente e se adapta!
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-end justify-content-center col-sm-12 col-md-12 col-lg-6 text-left cc-animate">
                                        <div style={{'width': '90%'}}>
                                            <img alt="Hipócrates" src="./images/hipocrates.png" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cc-section cc-slant no-pd-bt" id="section-educacao-corporativa">
                    <div className="container container-img">
                        <div className="row">
                            <div className="col-md-12 text-center cc-animate">
                                <div className="cc-title-dark text-center">
                                    <h2><span>&#60;</span> EDUCAÇÃO CORPORATIVA <span>&#62;</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex flex-row-reverse justify-content-center cc-section-margin-top">
                            <div className="d-flex flex-column col-sm-12 col-md-12 col-lg-7 text-justify cc-animate">
                                <p className="head-title text-dark">
                                    ORGANIZAÇÕES NÃO SE TRANSFORMAM, PESSOAS AS TRANSFORMAM!
                                </p>
                                <p className="lead text-dark">
                                    Muito mais que tecnologia, inovação é transformação! É mudar a forma de enxergar, de pensar e de prover assistência à saúde!
                                </p>
                                <p className="lead text-dark">
                                    Transformação Digital é sobre pessoas! Ser digital é ser mais humano, é colocar o paciente no centro da estratégia! A tecnologia é um excelente meio, mas nunca um fim!
                                </p>
                                <p className="lead text-dark mb-5rem">
                                    Nesse contexto, a EDUCAÇÃO CORPORATIVA é peça fundamental na construção de uma nova mentalidade e de uma cultura de aprendizado, que detecte problemas, enxergue oportunidades e crie soluções que entreguem valor aos pacientes e ao negócio.
                                </p>
                            </div>
                            <div className="d-flex align-items-end col-sm-12 col-md-12 col-lg-5 cc-animate">
                                <img alt="Steve Jobs" src="./images/jobs-3.png" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cc-section cc-slant cc-slant-white no-pd-bt" id="section-modulos">
                    <div className="container container-img">
                        <div className="row">
                            <div className="col-md-12 mb-5 cc-animate">
                                <div className="cc-title text-center">
                                    <h2><span>&#60;</span> MÓDULOS <span>&#62;</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 text-justify cc-animate">
                                <p className="lead">
                                    Nossos módulos abordam as principais METODOLOGIAS e FERRAMENTAS indispensáveis para os profissionais e organizações que querem transformar o presente e projetar o futuro da saúde:
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3 cc-animate">
                                <div className="d-flex align-items-center mb-3">
                                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                                        <img alt="Lean healthcare" width="55%" height="55%" src="./images/lean-health-care.png" className="img-fluid" />   
                                    </div>
                                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                                        <img alt="Digital healthcare" src="./images/digital-healthcare.png" className="img-fluid" /> 
                                    </div>
                                </div>
                                <div className="d-flex align-items-center cc-animate">
                                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                                        <img alt="Experiência do paciente" width="80%" height="80%" src="./images/experiencia-paciente.png" className="img-fluid" />   
                                    </div>
                                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                                        <img alt="Inteligência financeira" width="90%" height="90%" src="./images/inteligencia-financeira.png" className="img-fluid" /> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 cc-animate">
                                <img alt="Einstein" src="./images/einstein-2.png" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cc-section cc-slant" id="section-hacks-meetups">
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-md-12 text-center cc-animate">
                                <div className="cc-title-dark text-center">
                                    <h2><span>&#60;</span> HACKS E MEETUPS <span>&#62;</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex flex-row-reverse">
                            <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center cc-animate">
                                <p className="lead text-dark text-justify">
                                    Nossos HACKS são cursos rápidos, focados em um tema específico. São "atalhos" para uma nova ferramenta, habilidade ou Soft Skill.
                                </p>
                                <p className="lead text-dark text-justify">
                                    Os MEETUPS são encontros presenciais ou virtuais com o objetivo de estimular o network e o ecossistema de inovação na saúde, aproximando empresas, universidades e startups.
                                </p>
                                <p className="lead text-dark text-justify">
                                    Uma oportunidade para trocar experiências sobre inovação, conhecer algumas healthtechs e pensar em conjunto o futuro da assistência à saúde.
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center cc-animate">
                                <div style={{'width': '70%'}}>
                                    <img alt="Inovação" src="./images/inovacao-saude.png" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cc-section cc-slant cc-slant-white" id="section-consultoria">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-5">
                                <div className="cc-title text-center cc-animate">
                                    <h2><span>&#60;</span> CONSULTORIA <span>&#62;</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 d-flex text-justify cc-animate">
                                <p className="lead">
                                    Somos especialistas em projetos de INOVAÇÃO, de EFICIÊNCIA OPERACIONAL e de AGILIDADE ORGANIZACIONAL. A nossa metodologia Digital Healthcare Transformation, utiliza técnicas do Design Thinking, do Lean Six Sigma e dos Métodos Ágeis para promover uma cultura de transformação e de alta performance nas organizações de assistência à saúde.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 d-flex align-items-center justify-content-center mt-3 cc-animate">
                                <img alt="Consultoria" src="./images/metodologia-dht.png" className="img-fluid" /> 
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 d-flex text-justify cc-animate">
                                <p className="lead">
                                    Atuamos também como Startup Hunter, buscando parcerias para INOVAÇÃO ABERTA (Open Innovation), por meio da identificação de tecnologias com o maior potencial de transformação e pela seleção de HEALTH TECHS com as melhores soluções para a área da saúde.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex align-items-center justify-content-center mt-3 cc-animate">
                                <div className="startup-hunter-logo">
                                    <img alt="Startup Hunter" src="./images/startup-hunter.png" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cc-section cc-slant" id="section-conteudo">
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-md-12 text-center cc-animate">
                                <div className="cc-title-dark text-center">
                                    <h2><span>&#60;</span> CONTEÚDO <span>&#62;</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row flex-row-reverse">
                            <div className="col-md-6 d-flex flex-column justify-content-center cc-animate">
                                <p className="lead text-dark text-justify">
                                    Queremos desenvolver uma rede de Médicos, Gestores e Profissionais da Saúde, engajados e dispostos a dividir um pouco dos seus conhecimentos e experiências com toda a comunidade da CORTEX.
                                </p>
                                <p className="lead text-dark text-justify">
                                    Em nosso PODCAST, discutimos os desafios da inovação, num bate-papo descontraído, mas repleto de informações relevantes para os interessados em repensar a saúde.
                                </p>
                                <p className="lead text-dark text-justify">
                                    Em nosso BLOG, abordamos e comentamos os principais assuntos sobre estratégia, gestão e inovação na área de saúde.
                                </p>
                            </div>
                            <div className="col-md-6 icones-conteudo mb-3">
                                <div className="col-md-6 cc-animate">
                                    <div className="icone-conteudo">
                                        <img alt="Podcast" src="./images/cortex-cast.png" className="img-fluid" />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <button className="btn btn-primary cc-animate" disabled>
                                            Em breve!
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6 cc-animate">
                                    <div className="icone-conteudo">
                                        <img alt="Blog" src="./images/cortex-blog.png" className="img-fluid" />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <a className="btn btn-primary cc-animate" href="https://medium.com/@cortexclass">
                                            Leia agora
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cc-section cc-slant" id="section-contato">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center cc-animate">
                                <div className="cc-title text-center mb-3">
                                    <h2>VAMOS FALAR SOBRE O SEU PROJETO?</h2>
                                    <h3>Entre em contato e dê o primeiro passo rumo à transformação!</h3>
                                </div>

                                <div className="row justify-content-center mb-5">
                                    <div className="col-md-12">
                                        <form
                                            ref={form => this.formContatoEl = form}
                                            onSubmit={this.sendForm}
                                        >
                                            <div className="row">
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Nome"
                                                        onChange={this.handleChange('nome')}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="E-mail"
                                                        onChange={this.handleChange('email')}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Empresa"
                                                        onChange={this.handleChange('empresa')}
                                                    />
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Cargo"
                                                        onChange={this.handleChange('cargo')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Telefone"
                                                        onChange={this.handleChange('telefone')}
                                                    />
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Cidade/UF"
                                                        onChange={this.handleChange('cidade')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col" style={{ height: '150px' }}>
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Escreva sua mensagem..."
                                                        onChange={this.handleChange('mensagem')}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <div className="d-flex justify-content-center pt-3">
                                                    {this.getRecaptcha(this._reCaptchaRef)}
                                                </div>
                                                {this.state.isLoading ? <Spinner /> : null}
                                                <button type="submit" disabled={this.state.isLoading} className="btn btn-secondary cc-animate">
                                                    ENVIAR
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cc-section cc-slant" id="section-conteudos-exclusivos">
                    <div className="container">
                        <div className="row mb-3">
                            <div className="col-md-12 text-center cc-animate">
                                <div className="cc-title text-center">
                                    <h2>RECEBA CONTEÚDOS EXCLUSIVOS DA CORTEX!</h2>
                                    <h3 className="text-dark">Quer receber os melhores conteúdos sobre estratégia, gestão e inovação?</h3>
                                    <h3 className="text-dark">Inscreva-se agora e receba o nosso Guia de Inovação 2020!</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 d-flex align-items-center justify-content-center">
                                <div className="col-md-12">
                                    <form
                                        ref={form => this.formEl = form}
                                        onSubmit={this.enviarFormConteudosExclusivos}
                                    >
                                        <div className="row">
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Nome"
                                                    onChange={this.handleChange('nome')}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="E-mail"
                                                    onChange={this.handleChange('email')}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column align-items-center mt-3">
                                            <div className="d-flex justify-content-center pt-3">
                                                {this.getRecaptcha(this._reCaptchaRef2)}
                                            </div>
                                            {this.state.isLoading ? <Spinner /> : null}
                                            <button type="submit" disabled={this.state.isLoading} className="btn btn-primary cc-animate">
                                                ENVIAR
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex align-items-center justify-content-center">
                                <img alt="Inovação" src="./images/guia-inovacao.png" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>

                <footer id="footer">
                    <div className="container">
                        <div className="row mb-5 mt-5">
                            <div className="col-md-3 d-flex flex-column justify-content-center text-center">
                                <img alt="Cortex Class" src="./images/cortex-logo-white.png" className="img-fluid" />
                                <p className="strong mt-3" style={{'fontSize': '0.7rem'}}>EDUCAÇÃO | CONSULTORIA | TECNOLOGIA</p>
                            </div>
                            <div className="col-md-3 text-center">
                                <div className="mb-3">
                                    <img alt="Local" src="./images/local-icon.png" className="img-fluid" />
                                </div>
                                <p>
                                    Rua Ubaldino do Amaral, 927 <br />
                                    Alto da Rua XV - Curitiba/PR
                                </p>
                            </div>
                            <div className="col-md-3 text-center">
                                <div className="mb-3">
                                    <img alt="Contato" src="./images/contato-icon.png" className="img-fluid" />
                                </div>
                                <p>
                                    Fone +55 41 98753-9043 <br />
                                    E-mail: contato@cortexclass.com
                                </p>
                            </div>
                            <div className="col-md-3 text-center">
                                <div className="mb-3" style={{ lineHeight: '69px' }}>
                                    <img alt="Instagram" src="./images/instagram.png" className="img-fluid mr-2 " />
                                    <img alt="Facebook" src="./images/facebook.png" className="img-fluid" />
                                </div>
                                <p>
                                    facebook.com/cortexclass <br />
                                    instagram.com/cortexclass
                                </p>
                            </div>
                        </div>

                        <p className="text-center mb-5 strong">
                            Cortex Class Educação e Treinamentos LTDA. CNPJ 31.663.592/0001-55. <br />
                            Todos os Direitos Reservados © 2020
                        </p>
                    </div>
                </footer>
            </div>
        );
    }
}

export default App;
